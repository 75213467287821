import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Splash from "../components/splash"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import MediaImage from "../components/mediaImages"

const Header = styled.div`
  margin-top: 2rem;
  width: 600px;
  h1 {
    font: 400 48px ${props => props.theme.fonts.serif};
    margin-bottom: 0.3rem;
  }
  h2 {
    font: 400 20px ${props => props.theme.fonts.sans};
  }
`

const MediaTemplate = ({ images, options, children }) => (
  <Layout>
    <SEO title={`Media | ${options.title}`} />
    <Splash subtitle="Media" title={`Press`}>
      <Img style={{ minHeight: "150px" }} fluid={images[images.length - 1]} />
    </Splash>

    <div style={{ display: "flex" }}>
      <div>
        <MediaImage
          customStyle={{
            maxWidth: "100%",
            width: "300px",
            margin: "2.3rem 3rem 0 0",
            maxHeight: "unset",
          }}
          for={String(options["id"])}
        />
      </div>
      <div>
        <Header>
          <h1>{options.title}</h1>
          {options.author && options.publication ? (
            <h2>
              {options.author} - <em>{options.publication}</em>
            </h2>
          ) : (
            <h2>{options.author || options.publication}</h2>
          )}
        </Header>
        <section style={{ width: "600px", textAlign: "left !important" }}>
          {options.longText.map(l => (
            <p style={{ marginBottom: "1rem" }}>{l}</p>
          ))}
        </section>
        <Link to="/media">View More Press</Link>
      </div>
    </div>
  </Layout>
)
export default props => {
  const data = useStaticQuery(graphql`
    query {
      imageThree: file(relativePath: { eq: "banners/2-dag.png" }) {
        childImageSharp {
          fluid(maxWidth: 1180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const imageThree = data.imageThree.childImageSharp.fluid
  const images = [imageThree]
  return <MediaTemplate {...props} images={images} />
}
