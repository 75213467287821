import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const MediaImage = props => {
  const data = useStaticQuery(graphql`
    query {
      oneImage: file(relativePath: { eq: "media/1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      twoImage: file(relativePath: { eq: "media/2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      threeImage: file(relativePath: { eq: "media/3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fourImage: file(relativePath: { eq: "media/4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sixImage: file(relativePath: { eq: "media/6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sevenImage: file(relativePath: { eq: "media/7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eightImage: file(relativePath: { eq: "media/8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nineImage: file(relativePath: { eq: "media/9.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tenImage: file(relativePath: { eq: "media/10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      elevenImage: file(relativePath: { eq: "media/11.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      twelveImage: file(relativePath: { eq: "media/12.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      thirteenImage: file(relativePath: { eq: "media/13.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fourteenImage: file(relativePath: { eq: "media/14.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const oneImage = data.oneImage.childImageSharp.fluid
  const twoImage = data.twoImage.childImageSharp.fluid
  const threeImage = data.threeImage.childImageSharp.fluid
  const fourImage = data.fourImage.childImageSharp.fluid
  const sixImage = data.sixImage.childImageSharp.fluid
  const sevenImage = data.sevenImage.childImageSharp.fluid
  const eightImage = data.eightImage.childImageSharp.fluid
  const nineImage = data.nineImage.childImageSharp.fluid
  const tenImage = data.tenImage.childImageSharp.fluid
  const elevenImage = data.elevenImage.childImageSharp.fluid
  const twelveImage = data.twelveImage.childImageSharp.fluid
  const thirteenImage = data.thirteenImage.childImageSharp.fluid
  const fourteenImage = data.fourteenImage.childImageSharp.fluid

  const style = {
    maxHeight: "240px",
    width: "100%",
    maxWidth: "200px",
    ...props.customStyle,
  }
  const imgStyle = { objectPosition: "top" }
  switch (props.for) {
    case "1":
      return <Img imgStyle={imgStyle} style={style} fluid={oneImage} />
    case "2":
      return <Img imgStyle={imgStyle} style={style} fluid={twoImage} />
    case "3":
      return <Img imgStyle={imgStyle} style={style} fluid={threeImage} />
    case "4":
      return <Img imgStyle={imgStyle} style={style} fluid={fourImage} />
    case "6":
      return <Img imgStyle={imgStyle} style={style} fluid={sixImage} />
    case "7":
      return <Img imgStyle={imgStyle} style={style} fluid={sevenImage} />
    case "8":
      return <Img imgStyle={imgStyle} style={style} fluid={eightImage} />
    case "9":
      return <Img imgStyle={imgStyle} style={style} fluid={nineImage} />
    case "0":
      return <Img imgStyle={imgStyle} style={style} fluid={tenImage} />
    case "11":
      return <Img imgStyle={imgStyle} style={style} fluid={elevenImage} />
    case "12":
      return <Img imgStyle={imgStyle} style={style} fluid={twelveImage} />
    case "13":
      return <Img imgStyle={imgStyle} style={style} fluid={thirteenImage} />
    case "14":
      return <Img imgStyle={imgStyle} style={style} fluid={fourteenImage} />
    default:
      return <div />
  }
}
export default MediaImage
